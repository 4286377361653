import React from "react"

export const DataProcessingAddendumData = () => {
  const styles = `
    ol.lst-kix_t1muosvbwpsq-8 {
      list-style-type: none;
    }
    ol.lst-kix_t1muosvbwpsq-7 {
      list-style-type: none;
    }
    ol.lst-kix_t1muosvbwpsq-6 {
      list-style-type: none;
    }
    .lst-kix_19ohxd585dt-8 > li:before {
      content: "" counter(lst-ctn-kix_19ohxd585dt-8, lower-roman) ". ";
    }
    ol.lst-kix_t1muosvbwpsq-5 {
      list-style-type: none;
    }
    ol.lst-kix_t1muosvbwpsq-4 {
      list-style-type: none;
    }
    .lst-kix_19ohxd585dt-7 > li:before {
      content: "" counter(lst-ctn-kix_19ohxd585dt-7, lower-latin) ". ";
    }
    ol.lst-kix_t1muosvbwpsq-3 {
      list-style-type: none;
    }
    ol.lst-kix_t1muosvbwpsq-2 {
      list-style-type: none;
    }
    ol.lst-kix_t1muosvbwpsq-1 {
      list-style-type: none;
    }
    ol.lst-kix_t1muosvbwpsq-0 {
      list-style-type: none;
    }
    .lst-kix_t1muosvbwpsq-6 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-6;
    }
    ul.lst-kix_6jn4e5tcf6a-0 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-7.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-7 0;
    }
    .lst-kix_19ohxd585dt-0 > li:before {
      content: "" counter(lst-ctn-kix_19ohxd585dt-0, decimal) ") ";
    }
    .lst-kix_rlbefjgmiz8l-3 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-3;
    }
    ol.lst-kix_rlbefjgmiz8l-5.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-5 0;
    }
    .lst-kix_19ohxd585dt-7 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-7;
    }
    .lst-kix_t1muosvbwpsq-4 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-4;
    }
    .lst-kix_pps7yyjajhzo-4 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-4;
    }
    .lst-kix_19ohxd585dt-1 > li:before {
      content: "" counter(lst-ctn-kix_19ohxd585dt-1, lower-latin) ") ";
    }
    .lst-kix_19ohxd585dt-2 > li:before {
      content: "" counter(lst-ctn-kix_19ohxd585dt-2, lower-roman) ") ";
    }
    .lst-kix_19ohxd585dt-4 > li:before {
      content: "(" counter(lst-ctn-kix_19ohxd585dt-4, lower-latin) ") ";
    }
    .lst-kix_19ohxd585dt-3 > li:before {
      content: "(" counter(lst-ctn-kix_19ohxd585dt-3, decimal) ") ";
    }
    .lst-kix_19ohxd585dt-6 > li:before {
      content: "" counter(lst-ctn-kix_19ohxd585dt-6, decimal) ". ";
    }
    ol.lst-kix_19ohxd585dt-1.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-1 0;
    }
    .lst-kix_19ohxd585dt-5 > li:before {
      content: "(" counter(lst-ctn-kix_19ohxd585dt-5, lower-roman) ") ";
    }
    .lst-kix_rlbefjgmiz8l-7 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-7;
    }
    ol.lst-kix_19ohxd585dt-8 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-7 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-6 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-2.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-2 0;
    }
    ol.lst-kix_19ohxd585dt-5 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-4 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-3 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-2 {
      list-style-type: none;
    }
    .lst-kix_rlbefjgmiz8l-1 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-1;
    }
    ol.lst-kix_19ohxd585dt-1 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-0 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-5.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-5 0;
    }
    ul.lst-kix_prqf7rsb77cb-0 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-0.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-0 0;
    }
    ul.lst-kix_prqf7rsb77cb-6 {
      list-style-type: none;
    }
    ul.lst-kix_prqf7rsb77cb-5 {
      list-style-type: none;
    }
    ul.lst-kix_prqf7rsb77cb-8 {
      list-style-type: none;
    }
    ul.lst-kix_prqf7rsb77cb-7 {
      list-style-type: none;
    }
    ul.lst-kix_prqf7rsb77cb-2 {
      list-style-type: none;
    }
    ul.lst-kix_prqf7rsb77cb-1 {
      list-style-type: none;
    }
    ul.lst-kix_prqf7rsb77cb-4 {
      list-style-type: none;
    }
    ul.lst-kix_prqf7rsb77cb-3 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-0.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-0 0;
    }
    ul.lst-kix_6jn4e5tcf6a-4 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-8 {
      list-style-type: none;
    }
    ul.lst-kix_6jn4e5tcf6a-3 {
      list-style-type: none;
    }
    .lst-kix_pps7yyjajhzo-6 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-6;
    }
    ol.lst-kix_rlbefjgmiz8l-7 {
      list-style-type: none;
    }
    ul.lst-kix_6jn4e5tcf6a-2 {
      list-style-type: none;
    }
    ul.lst-kix_6jn4e5tcf6a-1 {
      list-style-type: none;
    }
    .lst-kix_19ohxd585dt-3 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-3;
    }
    ul.lst-kix_6jn4e5tcf6a-8 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-4 {
      list-style-type: none;
    }
    ul.lst-kix_6jn4e5tcf6a-7 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-3 {
      list-style-type: none;
    }
    ul.lst-kix_6jn4e5tcf6a-6 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-6 {
      list-style-type: none;
    }
    ul.lst-kix_6jn4e5tcf6a-5 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-5 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-0 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-2 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-1 {
      list-style-type: none;
    }
    .lst-kix_pps7yyjajhzo-0 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-0;
    }
    .lst-kix_19ohxd585dt-0 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-0;
    }
    ol.lst-kix_19ohxd585dt-0.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-0 0;
    }
    .lst-kix_19ohxd585dt-2 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-2;
    }
    .lst-kix_prqf7rsb77cb-8 > li:before {
      content: "\x5a0   ";
    }
    ol.lst-kix_t1muosvbwpsq-0.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-0 0;
    }
    .lst-kix_19ohxd585dt-1 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-1;
    }
    .lst-kix_pps7yyjajhzo-1 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-1;
    }
    .lst-kix_prqf7rsb77cb-2 > li:before {
      content: "\x5a0   ";
    }
    .lst-kix_prqf7rsb77cb-4 > li:before {
      content: "\x5cb   ";
    }
    ol.lst-kix_t1muosvbwpsq-3.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-3 0;
    }
    .lst-kix_t1muosvbwpsq-1 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-1;
    }
    .lst-kix_prqf7rsb77cb-6 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_prqf7rsb77cb-0 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_t1muosvbwpsq-6 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-6, decimal) ". ";
    }
    ol.lst-kix_pps7yyjajhzo-8.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-8 0;
    }
    .lst-kix_t1muosvbwpsq-2 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-2, lower-roman) ". ";
    }
    .lst-kix_pps7yyjajhzo-2 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-2;
    }
    .lst-kix_t1muosvbwpsq-0 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-0, decimal) ". ";
    }
    .lst-kix_t1muosvbwpsq-8 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-8, lower-roman) ". ";
    }
    ol.lst-kix_t1muosvbwpsq-2.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-2 0;
    }
    .lst-kix_t1muosvbwpsq-2 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-2;
    }
    .lst-kix_t1muosvbwpsq-8 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-8;
    }
    .lst-kix_dpvq5a4lc3is-7 > li:before {
      content: "\x5cb   ";
    }
    .lst-kix_pps7yyjajhzo-8 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-8;
    }
    .lst-kix_pps7yyjajhzo-5 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-5, lower-roman) ". ";
    }
    .lst-kix_pps7yyjajhzo-7 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-7, lower-latin) ". ";
    }
    ol.lst-kix_t1muosvbwpsq-1.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-1 0;
    }
    .lst-kix_rlbefjgmiz8l-2 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-2;
    }
    .lst-kix_rlbefjgmiz8l-8 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-8;
    }
    .lst-kix_t1muosvbwpsq-4 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-4, lower-latin) ". ";
    }
    ol.lst-kix_19ohxd585dt-4.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-4 0;
    }
    ol.lst-kix_rlbefjgmiz8l-2.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-2 0;
    }
    .lst-kix_rlbefjgmiz8l-4 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-4;
    }
    .lst-kix_6jn4e5tcf6a-1 > li:before {
      content: "\x5cb   ";
    }
    .lst-kix_6jn4e5tcf6a-0 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_6jn4e5tcf6a-2 > li:before {
      content: "\x5a0   ";
    }
    .lst-kix_6jn4e5tcf6a-5 > li:before {
      content: "\x5a0   ";
    }
    .lst-kix_pps7yyjajhzo-2 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-2, lower-roman) ". ";
    }
    .lst-kix_19ohxd585dt-8 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-8;
    }
    .lst-kix_pps7yyjajhzo-5 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-5;
    }
    .lst-kix_6jn4e5tcf6a-4 > li:before {
      content: "\x5cb   ";
    }
    .lst-kix_pps7yyjajhzo-3 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-3, decimal) ". ";
    }
    .lst-kix_6jn4e5tcf6a-3 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_pps7yyjajhzo-4 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-4, lower-latin) ". ";
    }
    ol.lst-kix_pps7yyjajhzo-7.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-7 0;
    }
    .lst-kix_rlbefjgmiz8l-3 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-3, decimal) ". ";
    }
    .lst-kix_t1muosvbwpsq-5 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-5;
    }
    .lst-kix_dpvq5a4lc3is-4 > li:before {
      content: "\x5cb   ";
    }
    .lst-kix_dpvq5a4lc3is-5 > li:before {
      content: "\x5a0   ";
    }
    .lst-kix_rlbefjgmiz8l-1 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-1, lower-latin) ". ";
    }
    .lst-kix_rlbefjgmiz8l-2 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-2, lower-roman) ". ";
    }
    .lst-kix_6jn4e5tcf6a-8 > li:before {
      content: "\x5a0   ";
    }
    ol.lst-kix_pps7yyjajhzo-2.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-2 0;
    }
    .lst-kix_dpvq5a4lc3is-2 > li:before {
      content: "\x5a0   ";
    }
    .lst-kix_dpvq5a4lc3is-6 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_rlbefjgmiz8l-0 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-0, decimal) ". ";
    }
    .lst-kix_t1muosvbwpsq-7 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-7;
    }
    .lst-kix_rlbefjgmiz8l-6 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-6;
    }
    .lst-kix_6jn4e5tcf6a-6 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_pps7yyjajhzo-1 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-1, lower-latin) ". ";
    }
    .lst-kix_6jn4e5tcf6a-7 > li:before {
      content: "\x5cb   ";
    }
    .lst-kix_pps7yyjajhzo-0 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-0, decimal) ". ";
    }
    .lst-kix_pps7yyjajhzo-7 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-7;
    }
    .lst-kix_dpvq5a4lc3is-3 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_dpvq5a4lc3is-0 > li:before {
      content: "\x5cf   ";
    }
    .lst-kix_dpvq5a4lc3is-1 > li:before {
      content: "\x5cb   ";
    }
    ol.lst-kix_pps7yyjajhzo-0 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-1.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-1 0;
    }
    .lst-kix_t1muosvbwpsq-3 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-3;
    }
    ol.lst-kix_pps7yyjajhzo-6 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-5 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-8 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-7 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-2 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-1 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-4 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-3 {
      list-style-type: none;
    }
    ol.lst-kix_rlbefjgmiz8l-8.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-8 0;
    }
    ol.lst-kix_t1muosvbwpsq-5.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-5 0;
    }
    .lst-kix_rlbefjgmiz8l-0 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-0;
    }
    .lst-kix_rlbefjgmiz8l-4 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-4, lower-latin) ". ";
    }
    .lst-kix_rlbefjgmiz8l-5 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-5, lower-roman) ". ";
    }
    .lst-kix_rlbefjgmiz8l-6 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-6, decimal) ". ";
    }
    .lst-kix_rlbefjgmiz8l-7 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-7, lower-latin) ". ";
    }
    ol.lst-kix_19ohxd585dt-3.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-3 0;
    }
    ol.lst-kix_rlbefjgmiz8l-1.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-1 0;
    }
    ol.lst-kix_rlbefjgmiz8l-7.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-7 0;
    }
    .lst-kix_rlbefjgmiz8l-8 > li:before {
      content: "" counter(lst-ctn-kix_rlbefjgmiz8l-8, lower-roman) ". ";
    }
    .lst-kix_19ohxd585dt-6 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-6;
    }
    ol.lst-kix_pps7yyjajhzo-6.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-6 0;
    }
    ol.lst-kix_t1muosvbwpsq-4.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-4 0;
    }
    .lst-kix_pps7yyjajhzo-3 > li {
      counter-increment: lst-ctn-kix_pps7yyjajhzo-3;
    }
    .lst-kix_t1muosvbwpsq-0 > li {
      counter-increment: lst-ctn-kix_t1muosvbwpsq-0;
    }
    ol.lst-kix_t1muosvbwpsq-8.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-8 0;
    }
    .lst-kix_prqf7rsb77cb-3 > li:before {
      content: "\x5cf   ";
    }
    ol.lst-kix_rlbefjgmiz8l-3.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-3 0;
    }
    ol.lst-kix_19ohxd585dt-8.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-8 0;
    }
    .lst-kix_prqf7rsb77cb-7 > li:before {
      content: "\x5cb   ";
    }
    .lst-kix_prqf7rsb77cb-5 > li:before {
      content: "\x5a0   ";
    }
    .lst-kix_19ohxd585dt-4 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-4;
    }
    ol.lst-kix_t1muosvbwpsq-6.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-6 0;
    }
    ol.lst-kix_rlbefjgmiz8l-6.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-6 0;
    }
    .lst-kix_prqf7rsb77cb-1 > li:before {
      content: "\x5cb   ";
    }
    .lst-kix_t1muosvbwpsq-5 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-5, lower-roman) ". ";
    }
    ol.lst-kix_rlbefjgmiz8l-4.start {
      counter-reset: lst-ctn-kix_rlbefjgmiz8l-4 0;
    }
    .lst-kix_t1muosvbwpsq-3 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-3, decimal) ". ";
    }
    .lst-kix_t1muosvbwpsq-7 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-7, lower-latin) ". ";
    }
    .lst-kix_t1muosvbwpsq-1 > li:before {
      content: "" counter(lst-ctn-kix_t1muosvbwpsq-1, lower-latin) ". ";
    }
    ol.lst-kix_pps7yyjajhzo-4.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-4 0;
    }
    ol.lst-kix_19ohxd585dt-5.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-5 0;
    }
    .lst-kix_19ohxd585dt-5 > li {
      counter-increment: lst-ctn-kix_19ohxd585dt-5;
    }
    .lst-kix_dpvq5a4lc3is-8 > li:before {
      content: "\x5a0   ";
    }
    .lst-kix_pps7yyjajhzo-6 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-6, decimal) ". ";
    }
    .lst-kix_pps7yyjajhzo-8 > li:before {
      content: "" counter(lst-ctn-kix_pps7yyjajhzo-8, lower-roman) ". ";
    }
    li.li-bullet-0:before {
      margin-left: -18pt;
      white-space: nowrap;
      display: inline-block;
      min-width: 18pt;
    }
    ul.lst-kix_dpvq5a4lc3is-7 {
      list-style-type: none;
    }
    ul.lst-kix_dpvq5a4lc3is-6 {
      list-style-type: none;
    }
    ul.lst-kix_dpvq5a4lc3is-5 {
      list-style-type: none;
    }
    .lst-kix_rlbefjgmiz8l-5 > li {
      counter-increment: lst-ctn-kix_rlbefjgmiz8l-5;
    }
    ol.lst-kix_t1muosvbwpsq-7.start {
      counter-reset: lst-ctn-kix_t1muosvbwpsq-7 0;
    }
    ul.lst-kix_dpvq5a4lc3is-4 {
      list-style-type: none;
    }
    ol.lst-kix_19ohxd585dt-6.start {
      counter-reset: lst-ctn-kix_19ohxd585dt-6 0;
    }
    ul.lst-kix_dpvq5a4lc3is-8 {
      list-style-type: none;
    }
    ol.lst-kix_pps7yyjajhzo-3.start {
      counter-reset: lst-ctn-kix_pps7yyjajhzo-3 0;
    }
    ul.lst-kix_dpvq5a4lc3is-3 {
      list-style-type: none;
    }
    ul.lst-kix_dpvq5a4lc3is-2 {
      list-style-type: none;
    }
    ul.lst-kix_dpvq5a4lc3is-1 {
      list-style-type: none;
    }
    ul.lst-kix_dpvq5a4lc3is-0 {
      list-style-type: none;
    }
    ol {
      margin: 0;
      padding: 0;
    }
    table td,
    table th {
      padding: 0;
    }
    .c0 {
      border-right-style: solid;
      padding: 5pt 5pt 5pt 5pt;
      border-bottom-color: #000000;
      border-top-width: 0pt;
      border-right-width: 0pt;
      border-left-color: #000000;
      vertical-align: top;
      border-right-color: #000000;
      border-left-width: 0pt;
      border-top-style: solid;
      border-left-style: solid;
      border-bottom-width: 0pt;
      width: 133.8pt;
      border-top-color: #000000;
      border-bottom-style: solid;
    }
    .c4 {
      border-right-style: solid;
      padding: 5pt 5pt 5pt 5pt;
      border-bottom-color: #000000;
      border-top-width: 0pt;
      border-right-width: 0pt;
      border-left-color: #000000;
      vertical-align: top;
      border-right-color: #000000;
      border-left-width: 0pt;
      border-top-style: solid;
      border-left-style: solid;
      border-bottom-width: 0pt;
      width: 115.8pt;
      border-top-color: #000000;
      border-bottom-style: solid;
    }
    .c21 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 20pt;
      font-family: "Arial";
      font-style: normal;
    }
    .c14 {
      color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 16pt;
      font-family: "Arial";
      font-style: normal;
    }
    .c26 {
      padding-top: 20pt;
      padding-bottom: 6pt;
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    .c2 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal;
    }
    .c28 {
      padding-top: 18pt;
      padding-bottom: 6pt;
      line-height: 1.1500000000000001;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    .c10 {
      color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal;
    }
    .c9 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 16pt;
      font-family: "Arial";
      font-style: normal;
    }
    .c8 {
      margin-left: 36pt;
      padding-top: 15pt;
      padding-left: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1666666666666667;
      text-align: left;
    }
    .c12 {
      margin-left: 36pt;
      padding-top: 15pt;
      padding-left: 0pt;
      padding-bottom: 5pt;
      line-height: 1.1666666666666667;
      text-align: left;
    }
    .c32 {
      margin-left: 18pt;
      padding-top: 15pt;
      padding-left: 0pt;
      padding-bottom: 5pt;
      line-height: 1.1666666666666667;
      text-align: left;
    }
    .c33 {
      margin-left: 22.5pt;
      padding-top: 15pt;
      padding-left: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1666666666666667;
      text-align: left;
    }
    .c25 {
      padding-top: 20pt;
      padding-bottom: 5pt;
      line-height: 1.1500000000000001;
      page-break-after: avoid;
      text-align: justify;
    }
    .c16 {
      padding-top: 18pt;
      padding-bottom: 5pt;
      line-height: 1.1500000000000001;
      page-break-after: avoid;
      text-align: justify;
    }
    .c5 {
      padding-top: 20pt;
      padding-bottom: 5pt;
      line-height: 1.1500000000000001;
      page-break-after: avoid;
      text-align: left;
    }
    .c7 {
      padding-top: 18pt;
      padding-bottom: 5pt;
      line-height: 1.1500000000000001;
      page-break-after: avoid;
      text-align: left;
    }
    .c3 {
      padding-top: 0pt;
      padding-bottom: 5pt;
      line-height: 1.1500000000000001;
      text-align: left;
      height: 11pt;
    }
    .c19 {
      border-spacing: 0;
      border-collapse: collapse;
      margin-right: auto;
    }
    .c22 {
      padding-top: 15pt;
      padding-bottom: 0pt;
      line-height: 1.1666666666666667;
      text-align: justify;
    }
    .c6 {
      padding-top: 0pt;
      padding-bottom: 5pt;
      line-height: 1.1500000000000001;
      text-align: left;
    }
    .c20 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.1500000000000001;
      text-align: justify;
    }
    .c1 {
      padding-top: 0pt;
      padding-bottom: 5pt;
      line-height: 1.1500000000000001;
      text-align: justify;
    }
    .c23 {
      text-decoration-skip-ink: none;
      -webkit-text-decoration-skip: none;
      color: #1155cc;
      text-decoration: underline;
    }
    .c24 {
      background-color: #ffffff;
      max-width: 468pt;
      padding: 72pt 72pt 72pt 72pt;
    }
    .c17 {
      color: inherit;
      text-decoration: inherit;
    }
    .c15 {
      padding: 0;
      margin: 0;
    }
    .c13 {
      height: 145pt;
    }
    .c34 {
      height: 131.5pt;
    }
    .c29 {
      height: 212.5pt;
    }
    .c27 {
      margin-right: -112.5pt;
    }
    .c11 {
      height: 239.5pt;
    }
    .c18 {
      font-weight: 700;
    }
    .c30 {
      height: 158.5pt;
    }
    .c35 {
      height: 129.2pt;
    }
    .c31 {
      height: 118pt;
    }
    .title {
      padding-top: 0pt;
      color: #000000;
      font-size: 26pt;
      padding-bottom: 3pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    .subtitle {
      padding-top: 0pt;
      color: #666666;
      font-size: 15pt;
      padding-bottom: 16pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    li {
      color: #000000;
      font-size: 11pt;
      font-family: "Arial";
    }
    p {
      margin: 0;
      color: #000000;
      font-size: 11pt;
      font-family: "Arial";
    }
    h1 {
      padding-top: 20pt;
      color: #000000;
      font-size: 20pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h2 {
      padding-top: 18pt;
      color: #000000;
      font-size: 16pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h3 {
      padding-top: 16pt;
      color: #434343;
      font-size: 14pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h4 {
      padding-top: 14pt;
      color: #666666;
      font-size: 12pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h5 {
      padding-top: 12pt;
      color: #666666;
      font-size: 11pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
    h6 {
      padding-top: 12pt;
      color: #666666;
      font-size: 11pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left;
    }
  `

  return (
    <div>
      <style type="text/css">{styles}</style>
      <h1 class="c26" id="h.oq05xqxs0a76">
        <span class="c21">Data Processing Addendum</span>
      </h1>
      <p class="c6">
        <span class="c2">Last updated: February 20, 2024</span>
      </p>
      <p class="c6">
        <span class="c10">
          <br />
          This Data Processing Agreement Addendum (&ldquo;DPA&rdquo;)
          supplements the Sideline Sports Terms of Service, as updated from time
          to time between Customer (the &ldquo;Controller&rdquo;) and Sideline
          Sports (the &ldquo;Processor&rdquo;, or other agreement between
          Customer and Sideline Sports governing Customer&rsquo;s use of the
          Services. In case of a conflict between the terms of this DPA and the
          Sideline Sports Terms of Service, this DPA will take precedence.
        </span>
      </p>
      <p class="c6">
        <span>
          The Controller and the Processor shall collectively be referred to as
          the &ldquo;
        </span>
        <span class="c18">Parties</span>
        <span class="c2">&rdquo;. </span>
      </p>
      <h2 class="c7" id="h.164z42x20yp0">
        <span class="c9">1. Introduction</span>
      </h2>
      <p class="c1">
        <span>On the basis of the Processor&rsquo;s Terms of Service (</span>
        <span class="c23">
          <a
            class="c17"
            href="https://sidelinesports.com/legal"
          >
            https://sidelinesports.com/legal
          </a>
        </span>
        <span>) (&ldquo;</span>
        <span class="c18">Terms</span>
        <span>
          &rdquo;), the Parties have entered into an agreement where the
          Processor undertakes to provide the Controller Sideline Services, as
          defined in the Terms (&ldquo;
        </span>
        <span class="c18">Sideline Services</span>
        <span>&rdquo; or the &ldquo;</span>
        <span class="c18">Services</span>
        <span class="c2">&rdquo;). </span>
      </p>
      <p class="c1">
        <span>
          In relation to the Services the Processor may process information and
          data, which can be considered Personal Data, as defined in section 2,
          based on Regulation 2016/679 of the European Parliament and of the
          Council on the protection of natural persons with regard to the
          processing of personal data and the free movement of such data
          (&ldquo;
        </span>
        <span class="c18">GDPR</span>
        <span>
          &rdquo;) or other applicable data protection legislation (collectively
          referred to as &ldquo;
        </span>
        <span class="c18">Data Protection Legislation</span>
        <span class="c2">&rdquo;), on behalf of the Controller.</span>
      </p>
      <p class="c1">
        <span class="c2">
          Where the Processor processes such data on behalf of the Customer, the
          Processor is considered a data processor according to Data Protection
          Legislation and the Controller a data controller.
        </span>
      </p>
      <p class="c1">
        <span>The purpose of this Data Processing Agreement (&ldquo;</span>
        <span class="c18">Processing Agreement</span>
        <span class="c2">
          &rdquo;) is to regulate the Parties&rsquo; rights and obligations in
          relation to such processing and to ensure the secure processing of the
          data.
        </span>
      </p>
      <p class="c1">
        <span>
          The Processor takes the matters of protection and security of Personal
          Data seriously and will process such data in accordance with the Data
          Protection Legislation and this Processing Agreement.
        </span>
      </p>
      <h2 class="c7" id="h.6df4ymvk72qw">
        <span class="c9">2. Scope of Processing</span>
      </h2>
      <p class="c6">
        <span class="c10">2.1 The Nature and Purpose of the Processing</span>
      </p>
      <p class="c1">
        <span class="c2">
          On the basis of the Terms, the Processor hosts all data inserted by
          the Controller into the systems provided by the Processor as part of
          the Services. In addition, the Processor has access to such data in
          order to provide technical and support services. As part of the
          Services, the Processor may also facilitate analysis of data inserted
          into the systems by the Controller (video material).
        </span>
      </p>
      <p class="c6">
        <span>
          In order to provide the Services, data processing on behalf of the
          Controller is thus required and that is the purpose of the processing
          activities undertaken by the Processor.
          <br />
        </span>
      </p>
      <p class="c6">
        <span class="c10">
          2.2 Categories of Personal Data and Data Subjects
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Processor is authorized to process all Personal Data inserted into
          the systems by the Controller.
        </span>
      </p>
      <p class="c1">
        <span>That includes the categories of personal data (&ldquo;</span>
        <span class="c18">Personal Data</span>
        <span>&rdquo;) and the categories of data subjects (&ldquo;</span>
        <span class="c18">Data Subjects</span>
        <span class="c2">
          &rdquo;), listed in Appendix 1 to the Processing Agreement.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Sideline Services are meant to be used for people 16 years of age
          and older. If the Controller registers Personal Data for younger
          people, the Controller must have a custodian consent to do so.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Sideline Services are not intended for processing of sensitive
          data/special categories of data, that is data revealing racial or
          ethnic origin, political opinions, religious or philosophical beliefs,
          or trade union membership, genetic data, biometric data for the
          purpose of uniquely identifying a natural person, data concerning
          health or data concerning a natural person&rsquo;s sex life or sexual
          orientation.
        </span>
      </p>
      <h2 class="c7" id="h.abjc6lm1izld">
        <span class="c9">3. Instructions</span>
      </h2>
      <p class="c1">
        <span class="c2">
          The Processor and other persons acting under the authority of the
          Processor who have access to the Personal Data shall process the
          Personal Data only on behalf of the Controller and only in compliance
          with the Terms, the Controller&#39;s documented instructions, and in
          accordance with this Processing Agreement, unless otherwise stipulated
          in applicable statutory laws.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Processor shall immediately inform the Controller if, in the
          Processor&#39;s opinion, any instruction provided by the Controller
          infringes the Data Protection Legislation.
        </span>
      </p>
      <h2 class="c7" id="h.cubizg7nx17d">
        <span class="c9">4. Obligations of the Controller</span>
      </h2>
      <p class="c1">
        <span class="c2">
          The Controller warrants that the Personal Data is processed for
          legitimate and objective purposes and that the Controller has the
          right to appoint the Processor to process the Personal Data on its
          behalf.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Controller is responsible for ensuring that a valid legal basis
          for the processing exists at the time of transferring the Personal
          Data to the Processor, including that any consent is given explicitly,
          voluntarily, unambiguously and on an informed basis.
        </span>
      </p>
      <p class="c1">
        <span>
          In addition, the Controller warrants that the Data Subjects to which
          the personal data pertains have been provided with sufficient
          information on the processing of their Personal Data.
        </span>
      </p>
      <h2 class="c7" id="h.q7yutesktnp">
        <span class="c9">5. Confidentiality and Training</span>
      </h2>
      <p class="c1">
        <span class="c2">
          The Processor and other persons acting under the authority of the
          Processor who have access to the Personal Data are subject to a duty
          of confidentiality in relation to all processing of Personal Data on
          behalf of the Controller. The Processor is responsible for ensuring
          that any Sub-processor, or other persons acting under its authority,
          is also subject to such duty of confidentiality.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          &nbsp;The Processor shall ensure that all employees, who have access
          to the Personal Data from the Controller, have received appropriate
          training on Data Protection Legislation and are aware, both of the
          Processor&rsquo;s duties, as well as their personal duties and
          obligations under such laws and this Agreement. &nbsp;
        </span>
      </p>
      <p class="c1">
        <span>
          The Controller is subject to a duty of confidentiality regarding any
          documentation and information, received from the Processor, related to
          the Processor&#39;s and its Sub-processors&#39; implemented technical
          and{" "}
        </span>
        <span>organisational</span>
        <span class="c2">
          &nbsp;security measures, or information which the Processor otherwise
          wants to keep confidential.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The confidentiality obligations also apply after the termination of
          the Processing Agreement.
        </span>
      </p>
      <h2 class="c7" id="h.invvqipn530m">
        <span class="c9">6. Security Measures</span>
      </h2>
      <p class="c20">
        <span class="c2">
          The Processor shall ensure that appropriate technical and
          organisational measures are implemented to ensure an appropriate level
          of security of the Personal Data processed on behalf of the
          Controller. The choice of technical and organisational security
          measures shall take into account the state of the art, the cost of
          implementation and the nature, scope, context and purposes of
          processing as well as the risks for the rights and freedoms of natural
          persons, which may be of varying likelihood and severity.
        </span>
      </p>
      <p class="c20">
        <span class="c2">
          A description of the security measures which the Processor is
          obligated to take, can be found in Appendix 2 to this Agreement.
        </span>
      </p>
      <h2 class="c7" id="h.ocih1hdjyyz9">
        <span class="c9">7. Data Subject&rsquo;s Rights</span>
      </h2>
      <p class="c20">
        <span class="c2">
          The Processor shall refer any data subjects&rsquo; requests which
          relate to the processing of Personal Data on behalf of the Controller
          to the Controller, without undue delay.
        </span>
      </p>
      <p class="c20">
        <span class="c2">
          The Processor shall assist the Controller by appropriate technical and
          organizational measures, and to the extent possible, to respond to
          requests for exercising the data subject&rsquo;s rights in accordance
          with Data Protection Legislation, e.g. access to Personal Data,
          information on processing, rectification or erasure of Personal Data,
          right to object to processing, limitation of processing, destruction
          of Personal Data and portability of Personal Data. &nbsp;
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Processor shall be compensated for its assistance at the
          Processor&#39;s then current rates, unless otherwise agreed.
        </span>
      </p>
      <h2 class="c7" id="h.b1xwph35gfxw">
        <span class="c9">8. Other Assistance to the Controller</span>
      </h2>
      <p class="c1">
        <span class="c2">
          If the Processor receives a request for access or information from the
          relevant supervisory authority relating to the registered Personal
          Data or processing activities subject to this Processing Agreement,
          the Processor shall notify the Controller, for the Controller&#39;s
          further processing thereof, unless the Processor is entitled to handle
          such request itself or mandatory legislation states otherwise.
        </span>
      </p>
      <p class="c20">
        <span class="c2">
          If the Controller decides to undertake an impact assessment in
          connection to certain processing activities, or consult the applicable
          supervisory authority, the Processor shall assist the Controller in
          accordance with Articles 35 and 36 of the GDPR.
        </span>
      </p>
      <p class="c20">
        <span class="c2">
          The Processor shall be compensated for its assistance at the
          Processor&#39;s then current rates, unless otherwise agreed.
        </span>
      </p>
      <h2 class="c16" id="h.wcnsdu9ve38v">
        <span class="c9">9. Notification of Personal Data Breach</span>
      </h2>
      <p class="c1">
        <span>
          The Processor shall notify the Controller without undue delay after
          becoming aware of a breach related to the processing of Personal Data
          (&quot;
        </span>
        <span class="c18">Personal Data Breach</span>
        <span class="c2">
          &quot;). The Controller is responsible for notifying the Personal Data
          Breach to the relevant supervisory authority, and the Data Subjects,
          as applicable.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The notification to the Controller shall as a minimum describe (i) the
          nature of the Personal Data Breach including where possible, the
          categories and approximate number of Data Subjects concerned and the
          categories and approximate number of Personal Data records concerned;
          (ii) the likely consequences of the Personal Data Breach; (iii) the
          measures taken or proposed to be taken by the Processor to address the
          Personal Data Breach, including, where appropriate, measures to
          mitigate its possible adverse effects.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          In the event the Controller is obliged to notify authorities or Data
          Subjects and requests the Processor&rsquo;s assistance, the Controller
          shall bear any costs related to such notifications.
        </span>
      </p>
      <h2 class="c7" id="h.an2uuui2cy19">
        <span class="c9">10. Use of Sub-Processors</span>
      </h2>
      <p class="c1">
        <span>
          By this Processing Agreement the Controller agrees to the Processors
          use of the processors (&ldquo;
        </span>
        <span class="c18">Sub-Processors</span>
        <span class="c2">&rdquo;) listed in Appendix 1 to the Agreement. </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Processor shall not engage new Sub-Processors for the processing
          of Personal Data under the Agreement, without notifying the
          Controller. The Processor shall inform the Controller of any intended
          changes concerning addition or replacement of any Sub-Processors, and
          the Controller has the right to object to such changes.
        </span>
      </p>
      <p class="c1">
        <span>
          The Processor shall ensure that its data protection obligations set
          out in the Processing Agreement and in Data Protection Legislation are
          imposed to any Sub-processors by a written agreement. Any
          Sub-Processor shall in particular provide sufficient guarantees to
          implement appropriate technical and{" "}
        </span>
        <span>organisational</span>
        <span class="c2">
          &nbsp;measures to comply with Data Protection Legislation, and provide
          the Processor and relevant supervisory authorities with access and
          information necessary to verify such compliance.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Processor shall remain fully liable to the Controller for the
          performance of any Sub-Processor.
        </span>
      </p>
      <h2 class="c7" id="h.j7wglsg3ubc">
        <span class="c9">11. Transfer</span>
      </h2>
      <p class="c1">
        <span>
          Disclosure, transfer of Personal Data or access to Personal Data from
          countries located outside EU/EEA (&quot;
        </span>
        <span class="c18">Third Country</span>
        <span class="c2">
          &quot;) may only occur if the Controller has consented to such
          transfer (including by accepting the use of a Sub-Processor
          established outside the EEA) and the conditions laid down in chapter V
          of the GDPR are complied with by the Processor.
        </span>
      </p>
      <h2 class="c7" id="h.m18t37zi62vx">
        <span class="c9">12. Audits</span>
      </h2>
      <p class="c1">
        <span>
          Processor shall provide the Controller with documentation of
          implemented technical and{" "}
        </span>
        <span>organisational</span>
        <span class="c2">
          &nbsp;measures to ensure an appropriate level of security, and other
          information necessary to demonstrate the Processor&#39;s compliance
          with its obligations under the Processing Agreement and relevant Data
          Protection Legislation.
        </span>
      </p>
      <p class="c1">
        <span>
          Controller and the supervisory authority under the relevant Data
          Protection Legislation shall be entitled to conduct audits, including
          on-premises inspections and evaluations of Personal Data being
          processed, the systems and equipment used for this purpose,
          implemented technical and{" "}
        </span>
        <span>organisational</span>
        <span>
          &nbsp;measures, including security policies and similar, and
          Sub-processors.{" "}
        </span>
        <span>Controller</span>
        <span class="c2">
          &nbsp;shall not be given access to information concerning
          Processor&#39;s other customers and information subject to
          confidentiality obligations.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          Controller is entitled to conduct such audits once a year. If the
          Controller appoints an external auditor to perform the audits, such
          external auditor shall be bound by a duty of confidentiality.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          Controller shall bear any costs related to audits initiated by
          Controller or accrued in relation to audits of Controller, including
          compensation to Processor for reasonable time spent by it and its
          employees complying with on premises audits.
        </span>
      </p>
      <h2 class="c28" id="h.kpl50g5k03jg">
        <span class="c9">13. Term and Termination</span>
      </h2>
      <p class="c1">
        <span class="c2">
          The Processing Agreement is valid for as long as the Processor
          processes Personal Data on behalf of the Controller.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          In the event of the Processor&#39;s breach of the Processing Agreement
          or non-compliance of the Data Protection Legislation, the Controller
          may (i) instruct the Processor to stop further processing of Personal
          Data with immediate effect; and/or (ii) terminate the Processing
          Agreement with immediate effect.
        </span>
      </p>
      <h2 class="c7" id="h.56hgb0g2g0t0">
        <span class="c9">14. Effects of Termination</span>
      </h2>
      <p class="c1">
        <span class="c2">
          The Processor shall, upon the termination of the Processing Agreement
          and at the choice of the Controller, delete or provide means for
          returning all Personal Data to the Controller, unless otherwise
          stipulated in applicable statutory law.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          Unless instructed otherwise by the Controller, Processor will retain
          data as necessary to comply with our legal obligations, resolve
          disputes, and enforce our agreement. 5 years after expiration, the
          data is hidden and marked for deletion, 3 years after that it is
          permanently deleted.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The Processor shall document in writing to the Controller that
          deletion has taken place in accordance with the Processing Agreement
          and as instructed by the Controller.
        </span>
      </p>
      <h2 class="c7" id="h.uee6e1o69lg1">
        <span class="c9">15. Limitation of Liability</span>
      </h2>
      <p class="c1">
        <span class="c2">
          Neither party shall be liable to the other party for any incidental,
          special, consequential, or indirect damages of any kind (including
          without limitation damages for interruption of business, loss of data,
          loss of profits or the like) regardless of the form of action, whether
          in contract, tort (including without limitation negligence), strict
          product liability, or other, even if advised of the possibility of
          such damages (jointly &quot;Indirect Damages&quot;).
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          Neither party shall be liable to the other party for
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          a) errors or delays that are outside the defaulting party&#39;s
          reasonable control, including general internet or
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          line delays, power failure or faults on any machines; or
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          b) errors caused by the other party&#39;s systems or actions,
          negligence or omissions, which shall be the sole
        </span>
      </p>
      <p class="c1">
        <span class="c2">responsibility of that party.</span>
      </p>
      <p class="c1">
        <span class="c2">
          The total and maximum liability in each twelve (12) month period of
          either party towards the other party under any provision of the Data
          Processing Agreement or any transaction contemplated by the Data
          Processing Agreement shall in no event exceed an amount equal to the
          total amounts paid for the Services under the Agreement in the twelve
          (12) months preceding the event that incurs liability.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          The above limitations shall not apply to damages attributable to
          fraud, gross negligence or intentional misconduct.
        </span>
      </p>
      <h2 class="c7" id="h.621ma2mifb1b">
        <span class="c9">16. Data Protection Officer</span>
      </h2>
      <p class="c6">
        <span class="c2">
          The Processor has a Data Protection Officer who can be contacted
          directly: dpo@sidelinesports.com
        </span>
      </p>
      <h2 class="c28" id="h.ye4soaausxgx">
        <span class="c9">17. Notices and Amendments</span>
      </h2>
      <p class="c1">
        <span class="c2">
          All notices relating to the Processing Agreement shall be submitted in
          writing to the email address stated on the first page of the
          Processing Agreement.
        </span>
      </p>
      <p class="c1">
        <span>In case changes in Data Protection Legislation, a </span>
        <span>judgement</span>
        <span class="c2">
          &nbsp;or opinion from another authoritative source causes another
          interpretation of Data Protection Legislation, or changes to the
          Services require changes to this Processing Agreement, the parties
          shall in good faith cooperate to update the Processing Agreement
          accordingly.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          Any modification or amendment of this Processing Agreement shall be
          effective only if agreed in writing and signed by both Parties.
        </span>
      </p>
      <h2 class="c7" id="h.ql375ks2grlb">
        <span class="c9">18. Governing Law and Legal Venue</span>
      </h2>
      <p class="c1">
        <span class="c2">
          This Processing Agreement shall be governed by and construed in
          accordance with the laws of Iceland. H&eacute;ra&eth;sd&oacute;mur
          Reykjav&iacute;kur is the court having exclusive jurisdiction.
        </span>
      </p>
      <h1 class="c25" id="h.80wie02dsvb1">
        <span class="c21">
          APPENDIX 1 - Description of Data Processing Activities
        </span>
      </h1>
      <h2 class="c7" id="h.4ywqctzai4cs">
        <span class="c9">1. XPS Network</span>
      </h2>
      <p class="c6">
        <span class="c2">
          In relation the XPS Network, the Processor shall be authorized to
          process all data inserted by the Controller into the system, including
          the following Personal Data on the following Data Subjects:
        </span>
      </p>
      <p class="c3">
        <span class="c2"></span>
      </p>
      <a id="t.1ddaed868b43f9aa629a6b9eba723b764e4c8eed"></a>
      <a id="t.0"></a>
      <table class="c19">
        <tr class="c11">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Account</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Users; trainers, guardians and athletes</span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6 c27">
              <span class="c2">
                User Info; name, user name, password, e-mail, country and
                information on sport.
              </span>
            </p>
            <p class="c6">
              <span class="c2">
                Controllers/users can also add information on age, social
                security number, address, telephone number, gender and photo.
              </span>
            </p>
            <p class="c6">
              <span class="c2">
                In Iceland data can be retrieved from the Registers Iceland.
              </span>
            </p>
            <p class="c6">
              <span class="c2">
                Data on athletes can also be retrieved from the Czech and Slovak
                soccer federations.
              </span>
            </p>
          </td>
        </tr>
        <tr class="c13">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Achievements and test results</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Athletes </span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Controllers can log test results for athletes, such as jump
                height, running speed etc.
              </span>
            </p>
            <p class="c6">
              <span class="c2">
                Controllers can also register achievements and other
                information, such as previous teams played for, jersey number
                etc.
              </span>
            </p>
          </td>
        </tr>
        <tr class="c31">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Attendance to team events</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Trainers and athletes</span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Information on whether the data subject attended a team event or
                if the data subject is absent. A team event can for example be
                sports practice or a sports match.
              </span>
            </p>
          </td>
        </tr>
        <tr class="c29">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Results from sports matches</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Athletes </span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Information on who played and information on results, for
                example who scores a goal etc.
              </span>
            </p>
            <p class="c6">
              <span class="c2">
                Such information is in some events imported from third parties
                by the Controllers, such as WyScout, Opta, SportsCode or
                DataVolley.
              </span>
            </p>
            <p class="c6">
              <span class="c2">
                Such data can also be retrieved from the Czech and Slovak soccer
                federations.
              </span>
            </p>
          </td>
        </tr>
        <tr class="c31">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Training logs</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Athletes </span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Athletes can log training, such as weight training or golf
                training. Athletes can further log meals, but this feature is
                rarely used.
              </span>
            </p>
          </td>
        </tr>
        <tr class="c30">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Questionnaires</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Users; trainers, guardians and athletes</span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Controllers can use the system to send out questionnaires and
                athletes/guardians can submit answers through the system. The
                subject of the questionnaire is up to the Controllers, but often
                they relate to training loads, performance evaluations etc.
              </span>
            </p>
          </td>
        </tr>
        <tr class="c34">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">GPS wearables or watches</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Athletes </span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Controllers can connect GPS wearables or watches from Garmin,
                Catapult, Kinexon, FirstBeat, Player TEK, StatSports and Polar
                to the system through API and collect data on athletes.
              </span>
            </p>
          </td>
        </tr>
        <tr class="c35">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Chat Messages (if activated by the Controller)
              </span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Users; trainers, guardians and athletes</span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Copies of chats </span>
            </p>
          </td>
        </tr>
        <tr class="c31">
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Video Analyzer</span>
            </p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">Athletes </span>
            </p>
          </td>
          <td class="c0" colspan="1" rowspan="1">
            <p class="c6">
              <span class="c2">
                Copies of videos, received from Handball AI, Catapult, Kinexon,
                Polar, StatSports, FirstBeat, HS&Iacute;, KS&Iacute; etc.
                Analysis of the videos are carried out by individuals, not AI.
              </span>
            </p>
          </td>
        </tr>
      </table>
      <p class="c3">
        <span class="c2"></span>
      </p>
      <h2 class="c7" id="h.w9jnk8fzq5ex">
        <span class="c9">2. Use of Sub-Processors</span>
      </h2>
      <p class="c6">
        <span class="c2">
          By this Agreement the Controller agrees that the Processor can use the
          following Sub-Processors:
        </span>
      </p>
      <ol class="c15 lst-kix_19ohxd585dt-0 start" start="1">
        <li class="c32 li-bullet-0">
          <span class="c10">Amazon Web Services</span>
        </li>
      </ol>
      <ul class="c15 lst-kix_6jn4e5tcf6a-0 start">
        <li class="c12 li-bullet-0">
          <span class="c2">Database service in Germany</span>
        </li>
        <li class="c12 li-bullet-0">
          <span class="c2">Virtual servers in Germany</span>
        </li>
        <li class="c12 li-bullet-0">
          <span class="c2">Email service in Germany</span>
        </li>
        <li class="c12 li-bullet-0">
          <span class="c2">
            File storage and backup services in UK for UK residents
          </span>
        </li>
        <li class="c12 li-bullet-0">
          <span class="c2">
            File storage and backup services in Ireland and Germany for other
            European residents
          </span>
        </li>
        <li class="c8 li-bullet-0">
          <span class="c2">
            File storage and backup services in the US, Brazil, Tokyo,
            Singapore, Australia, and Canada for residents outside EEA.
          </span>
        </li>
      </ul>
      <p class="c22">
        <span>
          As AWS&rsquo;s parent company is established in the United States, a
          transfer to the US can not be precluded. Such transfer would be based
          on the EU-US Data Privacy Framework: (
        </span>
        <span class="c23">
          <a
            class="c17"
            href="https://www.dataprivacyframework.gov/"
          >
            https://www.dataprivacyframework.gov/
          </a>
        </span>
        <span class="c2">). </span>
      </p>
      <ol class="c15 lst-kix_19ohxd585dt-0" start="2">
        <li class="c33 li-bullet-0">
          <span class="c10">Backblaze</span>
        </li>
      </ol>
      <ul class="c15 lst-kix_dpvq5a4lc3is-0 start">
        <li class="c8 li-bullet-0">
          <span class="c2">
            File storage of large videos in the Netherlands for European
            residents and for residents in other parts of the world.
          </span>
        </li>
        <li class="c8 li-bullet-0">
          <span class="c2">
            File storage of large videos in North America for North American
            residents.
          </span>
        </li>
      </ul>
      <p class="c22">
        <span>
          As BackBlaze is established in the United States, a transfer to the US
          can not be precluded (even though the data is stored within the EEA
          for European customers). Such transfer would be based on the EU-US
          Data Privacy Framework: (
        </span>
        <span class="c23">
          <a
            class="c17"
            href="https://www.dataprivacyframework.gov/"
          >
            https://www.dataprivacyframework.gov/
          </a>
        </span>
        <span class="c2">). </span>
      </p>
      <ol class="c15 lst-kix_19ohxd585dt-0" start="3">
        <li class="c33 li-bullet-0">
          <span class="c10">Microsoft Azure</span>
        </li>
      </ol>
      <ul class="c15 lst-kix_prqf7rsb77cb-0 start">
        <li class="c8 li-bullet-0">
          <span class="c2">
            Virtual server in Ireland for customers using the online store
            solution (xps clubs) hosting an application server, database and
            storing backups.
          </span>
        </li>
      </ul>
      <p class="c22">
        <span>
          As Microsoft (the parent company) is established in the United States,
          a transfer to the US can not be precluded. Such transfer would be
          based on the EU-US Data Privacy Framework: (
        </span>
        <span class="c23">
          <a
            class="c17"
            href="https://www.dataprivacyframework.gov/"
          >
            https://www.dataprivacyframework.gov/
          </a>
        </span>
        <span>). </span>
      </p>
      <h1 class="c5" id="h.qshvjbljat19">
        <span class="c21">APPENDIX 2 &ndash; Security Measures</span>
      </h1>
      <h2 class="c7" id="h.u9izv7if70d6">
        <span class="c9">1. Requirement of Information Security</span>
      </h2>
      <p class="c1">
        <span>
          The Processor, which according to the Agreement processes Personal
          Data on behalf of the Controller, shall implement appropriate
          technical and{" "}
        </span>
        <span>organisational</span>
        <span class="c2">
          &nbsp;measures as stipulated in Data Protection Legislation and/or
          measures imposed by relevant supervisory authority pursuant to Data
          Protection Legislation or other applicable statutory law to ensure an
          appropriate level of security.
        </span>
      </p>
      <p class="c1">
        <span>
          The Processor shall assess the appropriate level of security and take
          into account the risks related to the processing in relation to the
          Services, including risk for accidental or unlawful destruction, loss,
          alteration,{" "}
        </span>
        <span>unauthorised</span>
        <span class="c2">
          &nbsp;disclosure of, or access to Person Data transmitted, stored or
          otherwise processed.
        </span>
      </p>
      <p class="c1">
        <span class="c2">
          All transmissions of Personal Data between the Processor and the
          Controller or between the Processor and any third party shall be done
          at a sufficient security level, or otherwise as agreed between the
          Parties.
        </span>
      </p>
      <p class="c1">
        <span>
          This Appendix contains a general description of technical and{" "}
        </span>
        <span>organisational</span>
        <span class="c2">
          &nbsp;measures that shall be implemented by the Processor to ensure an
          appropriate level of security.
        </span>
      </p>
      <p class="c1">
        <span>
          To the extent the Processor has access to such information, the
          Processor shall provide the Controller with general descriptions of
          its Sub-processors&#39; technical and{" "}
        </span>
        <span>organisational</span>
        <span class="c2">
          &nbsp;measures implemented to ensure an appropriate level of security.
        </span>
      </p>
      <h2 class="c7" id="h.3ak1bz30vpwm">
        <span class="c9">2. Technical and Organisational Measures</span>
      </h2>
      <p class="c6">
        <span class="c10">2.1 Physical access control</span>
      </p>
      <p class="c6">
        <span class="c2">All data is hosted by sub-processors.</span>
      </p>
      <p class="c3">
        <span class="c10"></span>
      </p>
      <p class="c6">
        <span class="c10">2.2 Access control to systems</span>
      </p>
      <p class="c6">
        <span>Processor</span>
        <span>&nbsp;will take proportionate measures to prevent </span>
        <span>unauthorised</span>
        <span class="c2">
          &nbsp;access to systems holding Personal Data. Measures shall include:
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Password procedures (including e.g. requirements to length
          and/or special characters)
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Access to systems subject to approval from management
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; No access to systems for guest users or anonymous accounts
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Routines of manual lock when workstations are left unattended,
          and automatic lock within maximum 10 minutes
        </span>
      </p>
      <p class="c3">
        <span class="c10"></span>
      </p>
      <p class="c6">
        <span class="c10">2.3 Data entry control</span>
      </p>
      <p class="c6">
        <span>Processor</span>
        <span class="c2">
          &nbsp;will take proportionate measures to check and establish whether
          and by whom Personal Data has been supplied in the systems, modified
          or removed. Measures shall include:
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Differentiated access rights based on duties
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Automated log of user access, and frequent review of security
          logs to uncover and follow-up on any potential incidents
        </span>
      </p>
      <p class="c3">
        <span class="c10"></span>
      </p>
      <p class="c6">
        <span class="c10">2.4 Disclosure control</span>
      </p>
      <p class="c6">
        <span>Processor</span>
        <span>&nbsp;will take proportionate measures to prevent </span>
        <span>unauthorised</span>
        <span class="c2">
          &nbsp;access, alteration or removal of Personal Data during transfer
          of the Personal Data. Measures shall include:
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Use of state of the art encryption on all electronic transfer
          of Personal Data
        </span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Using a firewall filter, VPN or HTTPS for remote access,
          transport and communication of Personal Data
        </span>
      </p>
      <p class="c3">
        <span class="c10"></span>
      </p>
      <p class="c6">
        <span class="c10">2.5 Availability control</span>
      </p>
      <p class="c6">
        <span>Processor</span>
        <span class="c2">
          &nbsp;will take proportionate measures to ensure that Personal Data is
          protected from accidental destruction or loss. Measures shall include:
        </span>
      </p>
      <p class="c6">
        <span class="c2">&bull; Frequent back-up of Personal Data</span>
      </p>
      <p class="c6">
        <span class="c2">&bull; Remote storage</span>
      </p>
      <p class="c6">
        <span class="c2">&bull; Use of anti-virus/firewall protection</span>
      </p>
      <p class="c6">
        <span class="c2">
          &bull; Monitoring of systems in order to detect viruses etc.
        </span>
      </p>
      <p class="c6">
        <span class="c2">&bull; Uninterruptible power supply (UPS)</span>
      </p>
      <p class="c3">
        <span class="c10"></span>
      </p>
      <p class="c6">
        <span class="c10">2.5 Damage control</span>
      </p>
      <p class="c6">
        <span>Processor</span>
        <span class="c2">
          &nbsp;will take proportionate measures to prevent damage in the case
          of information leak.
        </span>
      </p>
      <p class="c6">
        <span class="c2">&bull; Log files are deleted periodically</span>
      </p>
      <p class="c6">
        <span class="c2">&bull; Passwords are one-way encrypted</span>
      </p>
    </div>
  )
}
