import React, { useRef, useEffect } from "react"
import ReactDOM from 'react-dom';

import Layout from "../components/layout"
import globalStyles from "../services/globalStyles.module.scss"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby"

import * as styles from "./legal.module.scss"
import { DataProcessingAddendumData } from "../data/legalContent/DataProcessingAddendum"

const DpaPage = ({
  data: {
    texts: { _ },
  },
  pageContext,
}) => {
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current

    if (container) {
      const shadowRoot = container.attachShadow({ mode: "open" })
      const mountPoint = document.createElement("div")
      shadowRoot.appendChild(mountPoint)

      // Render the provided React component inside the Shadow DOM - this is a hacky way to enforce styles isolation so document looks well
      ReactDOM.render(<DataProcessingAddendumData />, mountPoint)

      return () => {
        // Clean up React component when unmounting
        ReactDOM.unmountComponentAtNode(mountPoint)
      }
    }
  }, [])

  const languageCode = pageContext && pageContext.lang ? pageContext.lang : "en"

  return (
    <Layout
      header={
        <Header num={1} translations={_} fixed languageCode={languageCode} hideNav={true} />
      }
      translations={_}
      languageCode={languageCode}
    >
      <div
        className={[globalStyles.contentContainer190, styles.content].join(" ")}
      >
        <div ref={containerRef}></div>
      </div>
      <Header
        num={2}
        translations={_}
        className={globalStyles.hideOnMobile}
        languageCode={languageCode}
      />
      <Footer translations={_} languageCode={languageCode} />
    </Layout>
  )
}

export default DpaPage

export const query = graphql`
  query Dpa($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
  }
`
